import { graphql, useStaticQuery } from 'gatsby'


export const ReactJsBlogs = () => {


    const { wdwordpress } = useStaticQuery(graphql`
    {
      wdwordpress {
        posts(first: 4, where: {tag: "en reactjs"}) {
          nodes {
            single_blog {
              featuredImages {
                thumbnailImage {
                  sourceUrl
                }
              }
            }
            featuredImage {
              sourceUrl
              title
            }
            slug
            categories {
              nodes {
                name
              }
            }
            title
            postId
            content(format: RENDERED)
            author {
              name
            }
            date
          }
        }
        page(id: "cGFnZTo2") {
          homepage_contents {
            blog {
              blogbuttontext {
                en
                sv
              }
              text {
                en
                sv
              }
              title {
                en
                sv
              }
            }
          }
        }
      }
    }
  `)


    return wdwordpress
}