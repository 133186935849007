import React from "react"
import { useSelector } from "react-redux"

const ClientSide = () => {
  const language = useSelector(state => state.global.language)
  const data = useSelector(
    state => state.homepage?.page?.homepage_contents.joinWebduraSection
  )

  return (
    <section className="why_choose">
      <div className="container">
        <div className="phoneclr p-0">
          <div className="container">
            <div className="row text_side">
              <div className="txt_block">
                <div className="lftbrdr">
                  <h6>{data?.subtitle?.[language]}</h6>
                  <h2>{data?.title?.[language]}</h2>
                  <p>{data?.description?.[language]}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="choose_icon">
          {data?.list.map((feature, index) => (
            <div className="why_box" key={feature.title?.[language]}>
              <div className={`why whyic${index + 1}`} />
              <h2>{feature.title?.[language]}</h2>
              <p>{feature.description?.[language]}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ClientSide
