import React, { useRef, useState } from "react"
import { useSelector } from "react-redux"

import SampleIcon from "../../images/Icons/networking.svg"
import RightArrow from "../../images/right-arrow.svg"
import LeftArrow from "../../images/left-arrow.svg"
import RightArrowHash from "../../images/arrow-right-hash.svg"
import LeftArrowHash from "../../images/arrow-right-hash.svg"
import Slider from "react-slick"

const LeftArrows = props => {
  const { className, style, onClick } = props

  return (
    <img
      className={className}
      style={{ ...style }}
      onClick={onClick}
      src={LeftArrowHash}
      alt=""
      style={{ width: "50px", height: "auto", marginLeft: "-40px" }}
    />
  )
}

const RightArrows = props => {
  const { className, style, onClick } = props

  return (
    <img
      className={className}
      style={{ ...style }}
      onClick={onClick}
      src={RightArrowHash}
      alt=""
      style={{ width: "50px", height: "auto", marginRight: "-30px" }}
    />
  )
}

const Solutions = () => {
  const language = useSelector(state => state.global.language)
  const data = useSelector(
    state => state?.homepage?.page?.homepage_contents?.solutionsSection
  )

  const pillRef = useRef(null)
  const [scrollVal, setScrollVal] = useState(0)
  const handleScroll = scrollVal => {
    pillRef.current.scrollLeft += scrollVal
  }

  const handleScrollValue = () => {
    setScrollVal(pillRef.current.scrollLeft)
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <RightArrows style={{ marginRight: "100px" }} />,
    prevArrow: <LeftArrows />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerMode: true,
          dots: true,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  }
  return (
    <div className="solutions-container">
      <div
        className="row text_side_custom center-container"
        style={{
          paddingBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="txt_block">
          <div className="lftbrdr-solution-red">
            <h6 style={{ color: "#111" }}>{data?.subtitle?.[language]}</h6>
            <h2 style={{ color: "#111" }}>{data?.title?.[language]}</h2>
          </div>
        </div>
        <div className="para_block">
          <p style={{ color: "#111", fontSize: 15 }}>
            {data?.description?.[language]}
          </p>
        </div>
      </div>
      <div className="d-flex w-100 align-items-center mt-5">
        <div className="container">
          <Slider className="m-0" {...settings}>
            {data?.list &&
              data.list?.map((ele, index) => (
                <div
                  key={`${ele.icon.sourceUrl}${index}`}
                  className="slick-solution-slider-item"
                >
                  <div className="slick-solution-slider-item_top_wrap">
                    <img
                      style={{ width: "70px", height: "auto" }}
                      src={ele.icon.sourceUrl}
                      alt="Icons"
                    />
                    <h3
                      style={{
                        fontFamily: "Bebas Neue",
                        letterSpacing: 0,
                      }}
                    >
                      {ele.title?.[language]}
                    </h3>
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 13,
                    }}
                  >
                    {ele.text?.[language]}
                  </p>
                </div>
              ))}
            {data?.list &&
              data.list?.map((ele, index) => (
                <div
                  key={`${ele.icon.sourceUrl}${index}`}
                  className="slick-solution-slider-item"
                >

                  <div className="slick-solution-slider-item_top_wrap">
                    <img
                      style={{ width: "70px", height: "auto" }}
                      src={ele.icon.sourceUrl}
                      alt="Icons"
                    />
                    <h3
                      style={{
                        fontFamily: "Bebas Neue",
                        letterSpacing: 0,
                      }}
                    >
                      {ele.title?.[language]}
                    </h3>
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 13,
                    }}
                  >
                    {ele.text?.[language]}
                  </p>
                </div>
              ))}
          </Slider>
        </div>
        <div className="right-arrow"></div>
      </div>
    </div>
  )
}

export default Solutions
