import { graphql, useStaticQuery } from "gatsby"

export const CaseStudy = () => {
  const {
    wdwordpress: {
      page: { homepage_contents },
    },
  } = useStaticQuery(graphql`
    {
      wdwordpress {
        page(id: "cGFnZTo2") {
          homepage_contents {
            caseStudies {
              sliders {
                flag {
                  title
                  sourceUrl
                }
                modelImage {
                  title
                  sourceUrl
                }
                stacks {
                  name
                  img {
                    title
                    sourceUrl
                  }
                }
                subtitleOne {
                  en
                  sv
                }
                subtitleTwo {
                  en
                  sv
                }
                subtitleThree {
                  en
                  sv
                }
                textOne {
                  en
                  sv
                }
                textTwo {
                  en
                  sv
                }
                title {
                  en
                  sv
                }
              }
              subtitle {
                en
                sv
              }
              title {
                en
                sv
              }
            }
          }
        }
      }
    }
  `)
  return homepage_contents
}
