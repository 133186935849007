import { graphql, useStaticQuery } from "gatsby"

export const TestimonialsData = () => {
  const {
    wdwordpress: {
      page: { homepage_contents },
    },
  } = useStaticQuery(graphql`
    {
      wdwordpress {
        page(id: "cGFnZTo2") {
          homepage_contents {
            testimonials {
              clienttitle {
                en
                sv
              }
              testimonialTitle {
                en
                sv
              }
              anchorText {
                en
                sv
              }
              clienttitle {
                en
                sv
              }
              list {
                comment {
                  en
                  sv
                }
                img {
                  title
                  sourceUrl
                }
                name {
                  en
                  sv
                }
                project {
                  stacks {
                    name
                  }
                }
               
                position {
                  en
                  sv
                }
                
                video {
                  mediaItemUrl
                }
              }
              text {
                en
                sv
              }
            }
          }
        }
      }
    }
  `)
  return homepage_contents
}
