import styled from "styled-components";

export const ActivityImageGrid = styled.div`

display:grid;
width:80%;
grid-template-columns:2fr 1fr 1fr;
grid-gap :0px;
grid-auto-rows:minmax(200px, 200px);


img{
    object-fit:cover;
    width: 100%;
    height: 100%;
}

.rule_box{
    width: 100%;
    height: 100%;
    font-family: "Poppins", sans-serif;
   display:flex;
   flex-direction:column;
   justify-content:center;

    &_red{
        background-color:#dd3333;

        &_title{
            color:#000000;
            text-align:center;
            font-size:11px;
            font-weight:bold;
        }

        &_caption{

            color:#fff;
            text-align:center;
            font-size:12px;
            font-weight:bold;
        
        }

      
    }

    &_white{
        background-color:#fff;

        &_title{
            color:#dd3333;
            text-align:center;
            font-size:11px;
            font-weight:bold;
        }

        &_caption{

            color:#000000;
            text-align:center;
            font-size:12px;
            font-weight:bold;
        
        }
    }

   
}

figure{
    margin-bottom:0px;
    position:relative;
}

.stick_rule{
    background-color:#F7060B;
    height:200px;
    width: 200px;
    position:absolute;
    bottom:0;
}


.act_grd_item{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.fig1{
    grid-row-start: 1;
    grid-row-end: 3;

}



@media (min-width: 1250px) { 

   width:88%;
     
}

@media (max-width: 1250px) { 
   
   width:85%;

}

  @media (max-width: 980px) { 
   
   width:100%;
  
 }

 @media (max-width: 768px) { 
   
   width:100%;
   grid-template-columns:repeat(1, 1fr);
   grid-gap:5px;
   grid-auto-rows:minmax(300px, auto);
   
 }






`