import React, { useEffect, useRef, useState } from 'react'

function VideoPlayer({ url, className, activeIndex, itemIndex }) {

  const [playing, setPlaying] = useState(false);
  let ref = useRef()


  useEffect(() => {

    var vid = document.getElementById("myVideo");
    vid.play();

  }, [])


  useEffect(() => {
    var vid = document.getElementById("myVideo");
    if (activeIndex === itemIndex) {
      vid?.play();
    }
    else {
      vid?.pause();
    }

  }, [activeIndex])



  return (
   
    <video
      muted
      id="myVideo"
      controls
      ref={ref}
      src={url}
      type="video/mp4"
      className={className}
    ></video>

  )
}

export default VideoPlayer